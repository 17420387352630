import React from "react";
import { Navbar } from "../../components";
import { autoestima } from "../blog/imports";
import Footer from "../footer/Footer";
import './cursos.css';

function Formaciondebase() {
  return (
    <div className="main">
      <Navbar></Navbar>
      <div className="container-header">
        <h1 className="gradient__text">Workshop la fuerza transformadora de la Autoestima Virtual</h1>
      </div>
      <div className="cont-img"> <img src={autoestima} className='course-img' alt="" /></div>
      <div className="container-body">
        <p>
          17 y 18 de Septiembre

          <br /> <br /> <br />
          Un programa del Método Silva abierto a todo público. <br />
          ¿Alguna vez te hiciste estas preguntas?
          Más allá de lo que puedes manifestar a los demás, ¿Qué es lo que piensas de ti mismo? ¿Hasta qué punto tus pensamientos, decisiones y acciones te pertenecen de verdad o lo haces para agradar?
          Con una DURACIÓN de 8 hs nos enseña recursos para aplicar en lo cotidiano, paso a paso, como fortalecer el sentimiento de la propia valía.
          Es un conjunto de propuestas basadas en la RELAJACIÓN, VISUALIZACIÓN, consciencia plena y nuevos lenguajes; favoreciendo el incremento de la AUTOESTIMA, el DESARROLLO armónico de la salud, los procesos educativos y la creatividad.</p>

        <div className="container-header">
          <p className="gradient__text">Este Workshop te enseñará:</p>
        </div>
        <p>Liberarte de conceptos negativos sobre ti,
          Hacerte responsable de tu propia felicidad y dejar de culpar a los demás,
          Ser consciente de tus acciones, pensamientos y emociones,
          Libérate de la culpa y la vergüenza,
          Ser auténtico en las relaciones,
          Aprender a amarte.</p>
        <a href="https://api.whatsapp.com/send?phone=541134523591"><button className='button-header'>Contactate con nosotros</button></a>

      </div>

      <Footer />

    </div>
  );
}

export default Formaciondebase;