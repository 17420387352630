import React from 'react';
import './blog.css';
import { base, dinamica, autoestima, coaching, graduados } from './imports';
import { Article } from '../../components';
import { Link } from 'react-router-dom';

function Blog() {
    return (
        <div className='gpt3__blog section__padding' id='cursos'>
            <div className="gpt3__blog-heading">
                <h1 className='gradient__text'>Conoce nuestros cursos virtuales y presenciales</h1>
            </div>
            <div className="gpt3__blog-container">
                <div className="gpt3__blog-container_groupA">
                    <Link to='/formaciondebase#base'> <Article imgUrl={base} date='23, 24, 25, 28, 29 y 30 de Abril' title='Curso de Formación de Base del Método Silva VIRTUAL'
                        cursodata='El Método Silva reúne un conjunto de técnicas que desarrollan las capacidades innatas, p ero sin ejercitar, que tiene el ser humano. Cualquiera sea el punto de partida del alumno, su desarrollo, su nivel intelectual, o su edad, obtendrá al finalizar el curso un importante incremento en el uso de dichas capacidades y podrá aplicarlas para ayudarse a resolver los problemas de la vida cotidiana, mejorando así en gran medida su calidad de vida.'
                    /></Link>
                </div>
                <div className="gpt3__blog-container_groupB">
                    <Link to='/autoestima'> <Article imgUrl={autoestima} date='17 y 18 de Septiembre' title='La fuerza stransformadora de la Autoestima virtual' cursodata='Nos enseña recursos para aplicar en lo cotidiano. Es un conjunto de propuestas basadas en la relajación, visualización, consciencia plena y nuevos lenguajes' /></Link>
                    <Link to='/dinamica'><Article imgUrl={dinamica} date='Próximamente...' title='Dinámica del Método Silva' cursodata='Su práctica te convertirá en un imán que te permitirá atraer a tu vida lo que deseas, Tiene técnicas nuevas para la formulación de metas, manejo del estrés y de atraer el éxito.' /></Link>
                    <a href="https://api.whatsapp.com/send?phone=541134523591"> <Article imgUrl={graduados} date='Martes de 19hs a 21hs (ARG)' title='Reunión de Graduados' cursodata='Reunión semanal que permite ejercitar lo aprendido, profundizar distintos temas que hacen al mejoramiento personal.
                    Incluye, además, la ayuda mútua en proyectos comunes de salud y otras áreas.' /></a>
                    <a href="https://api.whatsapp.com/send?phone=541134523591"> <Article imgUrl={coaching} date='Personalizado' title='Coaching personalizado' cursodata='Encuentro virtual o presencial para recibir atención personalizada referente el crecimiento y mejoramiento de la calidad de vida.' /></a>
                </div>
            </div>
        </div>
    )
}

export default Blog
